<template>
  <v-row no-gutters :class="className">
    <v-col cols="4">
      <div class="white--text bg-secondary px-4 py-2 text-center discount-item-left">
        {{ rangeText }}
      </div>
    </v-col>
    <v-col cols="8">
      <div class="primary--text px-4 py-2 text-center">
        {{ priceText }}
      </div>
    </v-col>
  </v-row>
</template>

<script>
import unitText from "@/mixins/unitText.js"
export default {
  mixins: [unitText],
  props: {
    config: Object,
    product: Object,
    index: Number,
    length: Number,
  },
  computed: {
    className() {
      if (this.length <= 1) return null;
      if (this.index == 0) {
        return "border-lightGray-b";
      }
      return null
    },
    rangeText() {
      return this.discountRangeTextFunc({
        shipping_unit: this.product.shipping_unit,
        min: this.config.min,
        max: this.config.max
      })
    },
    priceText() {
      return this.priceTextFunc({
        price: this.config.price,
        unit: this.product.calculate_unit,
      })
    },
  },
};
</script>
<style lang="sass">
.discount-item-left
  border-radius: 4px 0 0 4px
</style>